import useAppTheme from '../../hooks/useAppTheme';
import Check from '../../icon/Check';
import {ContentColor} from '../../theme/useContentPalette';
import Typography, {Size} from '../../typography/Typography';

import {css} from '@emotion/react';
import {CSSInterpolation, SerializedStyles} from '@emotion/serialize';
import React, {useRef} from 'react';
import {
  AriaCheckboxGroupItemProps,
  VisuallyHidden,
  useCheckboxGroupItem,
  useFocusRing,
} from 'react-aria';
import {CheckboxGroupState} from 'react-stately';

export interface Props extends AriaCheckboxGroupItemProps {
  readonly ariaLabel: string;
  readonly label: React.ReactNode;
  readonly state: CheckboxGroupState;
  readonly rootCSS?: SerializedStyles;
}

export default function Checkbox(props: Props): JSX.Element {
  const {ariaLabel, label: labelText, rootCSS, state, ...checkboxProps} = props;
  const ref = useRef(null);
  const {inputProps, isPressed, labelProps} = useCheckboxGroupItem(
    {
      'aria-label': ariaLabel,
      ...checkboxProps,
    },
    state,
    ref,
  );
  const {focusProps, isFocusVisible} = useFocusRing();
  const theme = useAppTheme();
  const backgroundPalette = theme.palettes.background;
  const isDisabled = checkboxProps.isDisabled || state.isDisabled;
  const isSelected = state.isSelected(props.value);

  let labelStyle: CSSInterpolation = {};
  if (isDisabled) {
    labelStyle = {
      color: theme.palettes.content.neutral.disabled,
    };
  }

  let spanCSSOptions: CSSInterpolation;
  if (isSelected) {
    let backgroundColor = isDisabled
      ? backgroundPalette.accent.disabled
      : backgroundPalette.accent.default;
    if (isPressed) {
      backgroundColor = backgroundPalette.accent.active;
    }
    spanCSSOptions = {
      backgroundColor,
      color: '#FFFFFF',
    };
  } else {
    let borderColor = isDisabled
      ? backgroundPalette.neutral.disabled
      : backgroundPalette.neutral.default;
    if (isPressed) {
      borderColor = backgroundPalette.neutral.active;
    }
    spanCSSOptions = {
      border: `2px solid ${borderColor}`,
    };
  }

  return (
    <label
      css={[
        css({
          ...labelStyle,
          alignItems: 'center',
          cursor: isDisabled ? 'initial' : 'pointer',
          display: 'flex',
          fontSize: 14,
          lineHeight: '14px',

          '&:focus-visible': {
            outline: 'none',

            '& span': {},
          },

          '& + &': {
            marginTop: theme.spacing.x20,
          },
        }),
        rootCSS,
      ]}
      {...labelProps}
    >
      <VisuallyHidden>
        <input
          {...inputProps}
          {...focusProps}
        />
      </VisuallyHidden>

      <span
        css={[
          css({
            flex: '0 0 auto',
            alignItems: 'center',
            borderRadius: '4px',
            boxSizing: 'border-box',
            display: 'flex',
            height: 18,
            justifyContent: 'center',
            marginRight: theme.spacing.x8,
            width: 18,
            ...spanCSSOptions,
          }),
          isFocusVisible &&
            css({
              outline: `1.5px solid ${backgroundPalette.accent.focus}`,
              outlineOffset: 2,
            }),
        ]}
      >
        {isSelected && (
          <Check
            color={ContentColor.PRIMARY_CONTRAST}
            size={16}
            strokeWidth={4}
          />
        )}
      </span>

      <Typography
        color={isDisabled ? ContentColor.DISABLED : ContentColor.PRIMARY}
        size={Size.S}
      >
        {labelText}
      </Typography>
    </label>
  );
}
