import type {Option} from '../Option';

import Checkbox from './Checkbox';
import {CSSStyles} from '../../../../types/CSSStyles';
import FormElement from '../FormElement';

import React from 'react';
import {useCheckboxGroup} from 'react-aria';
import {CheckboxGroupProps, useCheckboxGroupState} from 'react-stately';

export interface Props extends CheckboxGroupProps {
  readonly options: ReadonlyArray<Option>;
  readonly 'aria-label'?: string;
  readonly errorMessage?: React.ReactNode;
  readonly rootStyle?: CSSStyles;
}

export default function CheckboxGroup(props: Props): JSX.Element {
  const {description, errorMessage, isRequired, label, options, rootStyle} =
    props;
  const state = useCheckboxGroupState(props);
  const {descriptionProps, errorMessageProps, groupProps, labelProps} =
    useCheckboxGroup(props, state);

  const elements = options.map((option) => {
    return (
      <Checkbox
        ariaLabel={option.ariaLabel}
        id={option.id}
        key={option.value}
        label={option.label}
        state={state}
        value={option.value}
      />
    );
  });

  return (
    <FormElement
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isDisabled={state.isDisabled}
      isInvalid={state.isInvalid}
      isRequired={isRequired}
      label={label}
      labelProps={labelProps}
      rootProps={groupProps}
      sx={rootStyle}
    >
      {elements}
    </FormElement>
  );
}
