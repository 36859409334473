import type {Validator} from './validator';

export default function allowNonEmptyString(
  validator?: Validator<unknown>,
): Validator<unknown> {
  return (value: unknown) => {
    if (typeof value === 'string' && value.trim().length > 0) {
      return;
    }
    validator?.(value);
  };
}
